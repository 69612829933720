import React from "react";

const App = () => {

    const newsData = [
        {
            image: "https://i.postimg.cc/gkCk3bbx/pexels-cottonbro-studio-3944425.jpg",
            title: "Trending Tech Products",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
            time: "2h ago",
        },
        {
            image: "https://i.postimg.cc/x1LjRp9t/pexels-cottonbro-studio-3944454.jpg",
            title: "Newly Introduced AI Model",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
            time: "Yesterday",

        }
    ];

    return (
        <div>
            <h1 className="text-center my-5 fontSize48">News about the wiserbee</h1>
            <div className="row m-3" >
                <div className="col-md-8">
                    <img src="http://cdn.bgr.com/2015/08/darth-vader.jpg" alt="register" />
                    <h1 className="mt-3">by Baron Watts on Aug 20, 2014</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem deleniti quae, neque libero voluptate maiores ullam unde voluptatem assumenda velit dolores impedit quis qui! Neque, cupiditate labore nulla? Atque, tenetur.
                        Numquam nobis nam voluptas blanditiis eveniet in quasi possimus voluptatem temporibus doloremque delectus dolorum, voluptatum laborum aut dolorem? In rerum necessitatibus soluta incidunt nihil numquam fugit quas pariatur dolores nesciunt?
                        Quibusdam placeat quisquam iure repellendus ad in, nihil numquam quaerat, facere alias illo. Tempora perferendis incidunt, ratione eveniet esse earum, corporis sit? Modi enim commodi odio placeat minus, error id?
                        Corrupti voluptates asperiores ratione laudantium, eveniet molestiae possimus deleniti officia, incidunt quae et. Amet, ducimus eum ipsa reprehenderit ad, et nihil, veritatis ea doloremque ab placeat dolore impedit, quia eius.</p>
                </div>
                <div className="col-md-4">
                <div className="news" id="news">
                    <div className="titletext">
                        <h1>News <span>Feed</span></h1>
                    </div>
                    <div className="container">
                        {newsData.map((news, index) => (
                            <div className="card" key={index}>
                                <div className="card__header">
                                    <img src={news.image} alt="card__image" className="card__image" width="600" />
                                </div>
                                <div className="card__body">
                                    <span className="tag">News</span>
                                    <h4>{news.title}</h4>
                                    <p>{news.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default App;
